//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {
    XM: () => import("@/components/XModal"),
  },
  props: {
    auditInfo: {
      type: Object,
      default: null,
    },
    //true为考勤申诉进来的页面  false为事务审批进来的页面
    isApply: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      optiops: [
        {
          label: "通过",
          value: 2,
        },
        {
          label: "驳回",
          value: 1,
        },
      ],
      rules: {
        Status: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
        AuditRemark: [
          {
            required: true,
            message: "理由必填",
            trigger: "blur",
          },
        ],
      },
      elForm: {
        Status: 2,
        AuditRemark: "", //审批留言
      },
    };
  },
  filters: {
    auditResultFilters(value) {
      switch (value) {
        case 1:
          return "待审核";
        case 2:
          return "通过";
        case 3:
          return "驳回";
        default:
          break;
      }
    },
  },
  methods: {
    submit() {
      this.$refs.elForm.validate(async (vaild) => {
        if (vaild) {
          this.$refs.xmodal.loadBtn(true);
          this.elForm.Id = this.auditInfo.Id;
          this.elForm.teamId = this.auditInfo.TeamId;
          this.$http
            .post(
              "/Attendance/AnomalyAppeals/SubmitAnomalyAppeal.ashx",
              this.elForm
            )
            .then((resp) => {
              if (resp.res == 0) {
                this.$notify({
                  type: "success",
                  message: resp.msg,
                });
                this.$modal.hide("applyAudit");
                this.$emit("upData");
                this.$emit("successHandle");
              }
            })
            .finally(() => this.$refs.xmodal.loadBtn(false));
        } else {
          this.$refs.container.focus();
        }
      });
    },
    closed() {
      Object.assign(this.$data.elForm, this.$options.data().elForm);
    },
  },
  created() {},
};
